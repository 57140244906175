import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import { SquareCardItem } from './SquareCardItem';
import { Image } from '@/components/Image';
import {
  getPageInfo,
  PageNames
} from '@/config/pages';

export type SquareCardProps = {
  imageUrl: string;
  altText: string;
  heading: string;
  description: string;
  isShowSubHeadingForPc?: boolean;
  brandSubHeading?: string;
  borderColor?: string;
  textColor?: string;
  hoverColor?: string;
  // TODO: 対応できないパターンがある
  pagePath?: keyof PageNames;
  linkImage?: string;
  slug?: string;
  name?: string;
}

const cardStyleProps = {
  borderRadius: '30px',
  overflow: 'hidden',
  w: { lg: '300px', xl: '330px' },
  h: { lg: '480px', xl: '460px' },
  bg: 'white',
  pb: 3,
  m: { base: 2, xl: 6 },
  maxW: 'sm',
};

export const SquareCard = ({
  imageUrl,
  altText,
  heading,
  description,
  brandSubHeading,
  isShowSubHeadingForPc = false,
  borderColor,
  textColor = 'brand.500',
  hoverColor,
  pagePath,
  linkImage,
  slug,
  name
}: SquareCardProps) => {
  const storeLink =
    (pagePath: keyof PageNames, slug?: string, name?: string) => {
      if (pagePath && slug && name) {
        return getPageInfo('studio/{slug}', { slug: slug, name: name }).href;
      } else {
        return getPageInfo(pagePath, {}).href;
      }
    };
  return (
    <>
      {pagePath ? (
        <Box>
          <Box
            flex-shrink={0}
            borderWidth={borderColor ? '1px' : '0px'}
            border={borderColor ? '1px solid' : '0px'}
            borderColor={borderColor}
            {...cardStyleProps}
            _hover={{
              bg: hoverColor,
            }}
          >
            <Link
              href={storeLink(pagePath, slug, name)}
            >
              <SquareCardItem
                imageUrl={imageUrl}
                altText={altText}
                textColor={textColor}
                heading={heading}
                description={description}
                brandSubHeading={brandSubHeading}
                isShowSubHeadingForPc={isShowSubHeadingForPc}
              />
            </Link>
          </Box>
          <Box
            m={'-60px 4px 0 auto'}
            width={'fit-content'}
          >
            <Link
              href={storeLink(pagePath, slug, name)}
            >
              <Image
                src={linkImage}
                alt={'もっと見る'}
                width={100}
                height={46}
              />
            </Link>
          </Box>
        </Box>
      ) : (
        <Box
          flex-shrink={0}
          borderWidth={borderColor ? '1px' : '0px'}
          border={borderColor ? '1px solid' : '0px'}
          borderColor={borderColor}
          {...cardStyleProps}
        >
          <SquareCardItem
            imageUrl={imageUrl}
            altText={altText}
            textColor={textColor}
            heading={heading}
            description={description}
            brandSubHeading={brandSubHeading}
            isShowSubHeadingForPc={isShowSubHeadingForPc}
          />
        </Box>
      )
      }
    </>
  );
};
