import {
  ExternalLinkIcon
} from '@chakra-ui/icons';
import {
  Text,
  Link as ChakraLink,
  TextProps,
  Box
} from '@chakra-ui/react';
import Link from 'next/link';
import { ReturnPageInfo } from '@/config/pages';

export type PcFooterLinkProps = {
  pageLink: ReturnPageInfo;
}

const LinkStyle: TextProps = {
  textStyle: 'h9',
  cursor: 'pointer',
  fontWeight: 'bold',
  pb: 1
};


export const PcFooterLink = ({
  pageLink
}: PcFooterLinkProps) => {
  return (
    <>
      {pageLink.external ? (
        <ChakraLink
          href={pageLink.href}
          target={'_blank'}
          _hover={{
            textDecoration: 'none',
          }}
          width={'calc(75%/3)'}
        >
          <Text
            {...LinkStyle}
          >
            {pageLink.name}
            <ExternalLinkIcon
              ml={1}
              mb={1}
            />
          </Text>
        </ChakraLink>
      ) : (
        <Box
          width={'calc(75%/3)'}
          {...LinkStyle}
        >
          <Link
            href={pageLink.href}
          >
            {pageLink.name}
          </Link>
        </Box>
      )}
    </>
  );
};
