import {
  default as NextLink,
  LinkProps as NextLinkProps
} from 'next/link';
import React from 'react';
import {
  TextLink,
  TextLinkProps
} from '@/components/Link/TextLink/TextLink';

export interface TextNextLinkProps extends Omit<NextLinkProps, 'as' | 'onTouchStart' | 'onMouseEnter'>, Omit<TextLinkProps, 'onClick' | 'href'> {
  children: React.ReactNode;
}

export const TextNextLink = (props: TextNextLinkProps) => {
  const {
    children,
    href,
    replace,
    scroll,
    shallow,
    passHref = true,
    prefetch,
    locale,
    legacyBehavior,
    ...linkTextProps
  } = props;
  return (
    <NextLink
      href={href}
      passHref={passHref || true}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      locale={locale}
      legacyBehavior={legacyBehavior}
    >
      <TextLink
        {...linkTextProps}
      >
        {children}
      </TextLink>
    </NextLink>
  );
};
