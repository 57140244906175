import {
  Box,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

type PaginationItemProps = {
  href?: string;
  active?: boolean;
  isLink?: boolean;
  onClick?(): void;
  children: React.ReactNode;
}

export const PaginationItem = (props: PaginationItemProps) => {
  const itemComponent = PaginationItemWithoutLink(props);

  if (!props.href || !props.isLink) {
    return itemComponent;
  }

  return (
    <Link
      href={props.href}
    >
      {itemComponent}
    </Link>
  );
};

const PaginationItemWithoutLink = ({ active, onClick, children }: PaginationItemProps) => {
  return (
    <Box
      w={9}
      h={9}
      m={1}
      display={'grid'}
      placeContent={'center'}
      border="1px"
      fontWeight="semibold"
      borderColor="gray.300"
      color="gray.500"
      bgColor={active ? 'gray.250' : undefined}
      cursor={'pointer'}
      _hover={{ bg: 'gray.250' }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

