import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import router from 'next/router';
import { useCallback } from 'react';
import { SpHamburgerMenuItem } from './SpHamburgerMenuItem';
import { SpHamburgerMenuItemWithIcon } from './SpHamburgerMenuItemWithIcon';
import { navbarHeight } from '@/components/Navbar/Navbar';
import {
  getPageInfo,
  PageName
} from '@/config/pages';
import { recoilCurrentCustomerInfoSelectors } from '@/stores/currentCustomerInfo';

type SpHamburgerMenuProps = {
  isOpen: boolean;
  onClose(): void;
  onLogout(): void;
}

const menus: PageName[] = [
  'guide',
  'brand',
  'about_rerakupay',
  'news',
  'column',
  'faq',
  'recruit'
];

const hamburgerIconUrl = '/images/hamburger_icon';

export const SpHamburgerMenu = ({ onLogout, isOpen, onClose }: SpHamburgerMenuProps) => {
  const isCustomerLogin = recoilCurrentCustomerInfoSelectors.useIsCustomerLogin();

  const handleDelayClose = useCallback(() => {
    window.setTimeout(() => onClose(), 300);
  }, [onClose]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      closeOnOverlayClick={true}
      variant={'alwaysOpen'}
    >
      <DrawerOverlay
        style={{ top: navbarHeight }}
        onClick={onClose}
      />
      <DrawerContent
        style={{
          top: navbarHeight,
          bottom: undefined,
          width: '80%',
        }}
        overflow={'auto'}
        height={'100dvh'}
        bg={'white'}
        px={6}
      >
        <Box>
          {menus.map((menu) => (
            <SpHamburgerMenuItem
              key={menu}
              pageLink={getPageInfo(menu, {})}
              onClose={handleDelayClose}
            />
          ))}
        </Box>
        <Box
          borderY={'1px solid'}
          borderColor={'gray.300'}
          mb={'60px'}
        >
          <SpHamburgerMenuItemWithIcon
            onClose={async () => {
              await router.push(getPageInfo('studio', {}).href);
              handleDelayClose();
            }}
            icon={`${hamburgerIconUrl}/search_icon.png`}
            altText={'店舗検索'}
          />
          {isCustomerLogin ? (
            <>
              <SpHamburgerMenuItemWithIcon
                onClose={async () => {
                  await router.push(getPageInfo('mypage', {}).href);
                  handleDelayClose();
                }}
                icon={`${hamburgerIconUrl}/mypage_icon.png`}
                altText={'マイページ'}
                color={'#45612C'}
              />
              <SpHamburgerMenuItemWithIcon
                onClose={async () => {
                  await router.push(getPageInfo('reraku_pay/login', {}).href);
                  handleDelayClose();
                }}
                icon={`${hamburgerIconUrl}/pay_icon.png`}
                altText={'Re.Ra.Ku PAY'}
              />
              <SpHamburgerMenuItemWithIcon
                onClose={async () => {
                  onLogout();
                  handleDelayClose();
                }}
                altText={'ログアウト'}
                color={'#45612C'}
              />
            </>
          ) : (
            <>
              <SpHamburgerMenuItemWithIcon
                onClose={async () => {
                  await router.push(getPageInfo('login', {}).href);
                  handleDelayClose();
                }}
                icon={`${hamburgerIconUrl}/login_icon.png`}
                altText={'ログイン'}
              />
              <SpHamburgerMenuItemWithIcon
                onClose={async () => {
                  await router.push(getPageInfo('reraku_pay/login', {}).href);
                  handleDelayClose();
                }}
                icon={`${hamburgerIconUrl}/pay_icon.png`}
                altText={'Re.Ra.Ku PAY'}
              />
            </>
          )}
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
