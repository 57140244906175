import { Box } from '@chakra-ui/react';
import React from 'react';

type DisplayBlockProps = {
  isShow?: boolean;
  children: React.ReactNode;
}

/**
 * isShowがtrueの場合のみ表示する
 * @param children
 * @param isShow
 * @constructor
 */
export const DisplayBlock = ({ children, isShow = true }: DisplayBlockProps) => {
  return (
    <Box
      display={isShow ? 'block' : 'none'}
    >
      {children}
    </Box>
  );
};
