import { Box, Text } from '@chakra-ui/react';
import { Image } from '@/components/Image';

export type SquareCardItemProps = {
  imageUrl: string;
  altText: string;
  textColor?: string;
  heading: string;
  description: string;
  brandSubHeading?: string,
  isShowSubHeadingForPc: boolean;
};

export const SquareCardItem = ({
  imageUrl,
  altText,
  textColor,
  heading,
  description,
  brandSubHeading,
  isShowSubHeadingForPc
}: SquareCardItemProps) => {
  return (
    <>
      <Box
        position={'relative'}
        height={'220px'}
        width={'330px'}
      >
        <Image
          src={imageUrl}
          objectPosition={'50%, 50%'}
          alt={altText}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box>
        <Text
          color={textColor}
          textAlign={'center'}
          textStyle={'h3'}
          fontWeight={'bold'}
          whiteSpace={'pre-line'}
          pt={4}
          pb={2}
        >
          {heading}
        </Text>
        {isShowSubHeadingForPc &&
          <Text
            color={textColor}
            textAlign={'center'}
            textStyle={'h6'}
            fontWeight={'bold'}
            whiteSpace={'pre-line'}
            pb={2}
          >{brandSubHeading}</Text>
        }
      </Box>
      <Box>
        <Text
          textAlign={'justify'}
          whiteSpace={'pre-line'}
          letterSpacing={'0.03em'}
          px={6}
          pb={3}
        >
          {description}
        </Text>
      </Box>
    </>
  );
};
