import {
  Text,
  TextProps,
  Box
} from '@chakra-ui/react';
import { HeadingTitle } from '@/components/HeadingTitle/HeadingTitle';
interface HeadingTitleWithTextProps extends TextProps {
  title: string;
  subTitle: string;
  text: string;
  margin?: string;
  color?: string;
}

export const HeadingTitleWithText = ({
  title,
  subTitle,
  text,
  margin,
  color = 'brand.500',
  ...props
}: HeadingTitleWithTextProps) => {
  return (
    <Box
      {...props}
    >
      <HeadingTitle
        title={title}
        subTitle={subTitle}
        m={margin}
        whiteSpace={'pre-wrap'}
        color={color}
      />
      <Text
        mt={{ lg: -2 }}
        lineHeight={'200%'}
        fontWeight={500}
        fontStyle={'normal'}
        letterSpacing={'0.045em'}
        textStyle={'h5'}
        whiteSpace={'pre-line'}
      >
        {text}
      </Text>
    </Box>
  );
};
