import { useEffect } from 'react';
import {
  useSetRecoilState
} from 'recoil';
import {
  bookingInputState,
  defaultBookingInput
} from '@/stores/bookingInput';
import {
  currentCustomerInfoState,
  CurrentCustomerInfoState,
  defaultCurrentCustomerInfo
} from '@/stores/currentCustomerInfo';
import {
  CustomerRegisterState,
  customerRegisterState,
  defaultCustomerRegister
} from '@/stores/customerRegister';
import { BookingInputState } from '@/types/bookingInput';


export type RecoilControllerProps = {
  currentCustomer?: Partial<CurrentCustomerInfoState>;
  bookingInput?: Partial<BookingInputState>;
  customerRegister?: Partial<CustomerRegisterState>;
}

export const RecoilController = ({
  currentCustomer,
  bookingInput,
  customerRegister,
}: RecoilControllerProps) => {
  const setCurrentCustomerInfo = useSetRecoilState(currentCustomerInfoState);
  const setBookingInputState = useSetRecoilState(bookingInputState);
  const setCustomerRegisterState = useSetRecoilState(customerRegisterState);
  useEffect(() => {
    if (currentCustomer) {
      setCurrentCustomerInfo({
        ...defaultCurrentCustomerInfo,
        ...currentCustomer
      });
    }
    if (bookingInput) {
      setBookingInputState({
        ...defaultBookingInput,
        ...bookingInput
      });
    }
    if (customerRegister) {
      setCustomerRegisterState({
        ...defaultCustomerRegister,
        ...customerRegister,
      });
    }
  }, [
    bookingInput,
    currentCustomer,
    customerRegister,
    setBookingInputState,
    setCurrentCustomerInfo,
    setCustomerRegisterState
  ]);

  return null;
};
